import { ITranslation } from './types'

export const pl: ITranslation = {
  addCardScreen: {
    headerTitle: 'Dodaj kartę',
    stripeSetupIntentError: {
      header: 'Błąd: $$',
      body: 'Coś poszło nie tak. Spróbuj innej karty. Szczegóły błędu: $$',
    },
  },
  button: {
    addCard: 'Dodaj kartę',
    completeRegistration: 'Zakończ rejestrację',
    confirm: 'Potwierdź',
    continue: 'Dalej',
    getStarted: 'Rozpocznij',
    goToPayment: 'Metody płatności',
    login: 'Zaloguj się',
    removeCard: 'Usuń kartę',
    signup: 'Zarejestruj się',
    back: 'Powrot',
    skip: 'Pominąć',
    error: 'Błąd',
    payment: 'Ponów płatność',
    ok: 'Ok',
    yes: 'Tak',
    no: 'Nie',
  },
  contactUsScreen: {
    address: 'Adres',
    email: 'Email',
    headerTitle: 'Kontakt',
    phone: 'Telefon',
    dataProtectionEmail: 'Data Deletion',
    dataProtectionTitle:
      'If you would like to request your personal data to be deleted, please send us an email to: ',
  },
  api: {
    E_FORCED_LOGOUT: 'Zostałeś wylogowany z powodu braku dostępu.',
  },
  errors: {
    error: 'Błąd',
    E_NO_INTERNET: 'Brak połączenia z internetem',
    E_CUSTOMER_NOT_FOUND: 'Nie znaleziono użytkownika',
    E_CUSTOMER_INVALID_CREDENTIALS: 'Błędny email lub hasło',
    E_FORBIDDEN: 'Brak dostępu',
    E_NOT_FOUND: 'Nie znaleziono',
    E_ORDER_NOT_FOUND: 'Zamówienie nie istnieje',
    E_PAYMENT_ERROR: 'Coś poszło nie tak podczas łączenia z systemem płatności',
    E_STORE_NOT_FOUND: 'Nie znaleziono sklepu',
    E_UNAUTHORIZED: 'Nie potwierdzono dostępu',
    E_CUSTOMER_PAYMENT_METHOD_NOT_FOUND: 'Użytkownik nie ustawił metody płatności',
    E_INVALID_BODY: 'Uzupełnij brakujące lub błędnie uzupełnione pola',
    E_ORDER_CUSTOMER_RELATION: 'Zamówienie nie należy do użytkownika',
    E_RESET_CODE_ALREADY_GENERATED: 'Kod został już wysłany na ten adres email. Spróbuj później.',
    E_RESET_CODE_INVALID: 'Niepoprawny kod resetujący',
    E_RESET_CODE_NOT_FOUND: 'Kod resetujący wygasł lub jest niepoprawny',
    E_SHOPIFY_DUPLICATE_EMAIL: 'Adres email jest już zajęty',
    E_SHOPIFY_DUPLICATE_PHONE: 'Numer telefonu jest już zajęty',
    E_SHOPIFY_INVALID_ORDER_ID: 'Zamówienie nie istnieje',
    E_SHOPIFY_CUSTOMER_NOT_FOUND: 'Nie znaleziono użytkownika',
    E_CUSTOMER_NO_PAYMENT_METHOD: 'Użytkownik nie ustawił metody płatności',
    E_CUSTOMER_UNSETTLED_TRANSACTIONS: 'Your previous transaction failed. Contact our support.',
    E_CUSTOMER_CARD_EXPIRED: 'Kartka płatnicza wygasła',
    SOMETHING_WRONG: 'Coś poszło nie tak. Spróbuj jeszcze raz.',
  },
  forgotPasswordScreen: {
    title: 'Przypominanie hasła',
    wrongCodeErrorMessage: 'Błędny kod',
    wrongEmailErrorMessage: 'Błędny email',
    codeErrorMessage: 'To pole musi zostać wypełnione.',
    emailErrorMessage: 'To pole musi zostać wypełnione.',
    passwordErrorMessage: 'To pole musi zostać wypełnione.',
    shortPasswordErrorMessage: 'Hasło musi zawierać minimum 8 znaków.',
    alreadyHaveCode: 'Już mam kod resetujący',
  },
  form: {
    email: 'Adres email',
    emailPlaceholder: 'jan.kowalski@email.com',
    firstName: 'Imię',
    firstNamePlaceholder: 'Jan',
    lastName: 'Nazwisko',
    lastNamePlaceholder: 'Kowalski',
    newPassword: 'Nowe hasło',
    password: 'Hasło',
    passwordPlaceholder: 'Min. 8 znaków',
    resetCode: 'Kod do zresetowania hasła',
    resetCodePlaceholder: '12345',
    productName: 'Nazwa produktu',
    productQuantity: 'Ilość',
    labelPasswordShow: 'pokaż',
    labelPasswordHide: 'ukryj',
    taxId: 'NIP (opcjonalnie)',
    taxIdPlaceholder: '1234567890',
    companyName: 'Nazwa firmy (opcjonalnie)',
    companyNamePlaceholder: 'Firma sp. z o.o.',
    phone: 'Phone',
    phonePlaceholder: '13888888888',
    address: 'Adres',
    addressPlaceholder: 'Ulica 1, 00-000 Miasto',
    addressError: 'To pole musi zostać wypełnione.',
    firstNameErrorMessage: 'To pole musi zostać wypełnione.',
    lastNameErrorMessage: 'To pole musi zostać wypełnione.',
    emailErrorMessage: 'To pole musi zostać wypełnione.',
    invalidEmailErrorMessage: 'Email jest niepoprawny.',
    passwordErrorMessage: 'To pole musi zostać wypełnione.',
    shortPasswordErrorMessage: 'Hasło musi zawierać minimum 8 znaków.',
    taxIdErrorMessage: 'NIP jest niepoprawny.',
    taxIdEmptyErrorMessage: 'To pole musi zostać wypełnione.',
    companyNameEmptyErrorMessage: 'To pole musi zostać wypełnione.',
    phoneEmptyErrorMessage: 'Phone is not allowed to be empty',
    phoneInvalidErrorMessage: 'A phone number should be 8-15 digits(include country code)',
  },
  loginScreen: {
    forgotPassword: 'Zapomniałaś/eś hasła?',
    subtitle: 'Zaloguj się za pomocą adresu email i hasła',
    title: 'Witaj ponownie',
  },
  homeScreen: {
    cardInvalidSubtitle: 'Musisz mieć potwierdzoną metodę płatności, aby wejść do sklepu.',
    cardValidTitle: 'Zeskanuj i wejdź',
    partyText: 'Liczba osób w twojej grupie:',
    greeting: 'Witaj',
    errorUnpaid: 'Musisz opłacić wszystkie swoje paragony zanim będziesz miał dostęp do sklepu',
    errorUnpaidButton: 'Zobacz nieopłacone paragony',
    errorExpired: 'Nie masz ważnych kart. Proszę dodaj nową kartę.',
    errorExpiredButton: 'Dodaj kartę',
    errorNoCard: 'Do konta nie dodano żadnej karty. Proszę dodaj nową kartę.',
    errorNoCardButton: 'Dodaj kartę',
    errorUnknown: 'Nieznany błąd',
  },
  menu: {
    access: 'Wejście',
    payment: 'Płatności',
    receipts: 'Rachunki',
    settings: 'Kontakt',
  },
  onboardingScreen: {
    subtitle1: 'Wejdź do sklepu używając kodu z aplikacji',
    title1: 'Zeskanuj, aby wejść',
    subtitle2: 'Pozwój im wejść skanująć twój kod, następnie wejdź',
    title2: 'Kilka osób?',
    subtitle3: 'Wszystko, co weźmiesz z półki, jest automatycznie dodawane do twojego koszyka',
    title3: 'Wybierz produkty',
    subtitle4: 'Gotowe? Po prostu wyjdź ze sklepu. Podsumowanie zakupów dostaniesz w aplikacji.',
    title4: 'Gotowe? Zrób zakupy',
  },
  registerLoginScreen: {
    register: 'Zarejestruj się',
    login: 'Zaloguj się',
  },
  paymentScreen: {
    card: {
      subtitle: 'To jest twoja aktualna metoda płatności za zakupy',
    },
    endsWith: '* * * * $$',
    noCard: {
      subtitle: 'Nie masz wybranej metody płatności. Kliknij poniżej i dodaj kartę płatniczą.',
    },
    removeCard: {
      cancel: 'Anuluj',
      delete: 'Usuń',
      subtitle: 'Na pewno chcesz usunąć kartę?',
      title: 'Usuń kartę',
    },
    title: 'Metoda płatności',
    addCreditCard: {
      cardView: {
        labels: {
          expiry: 'Miesiąc/Rok',
        },
      },
      labels: {
        name: 'Imię właściciela karty',
        number: 'Numer karty',
        expiry: 'Data ważności',
        cvc: 'CVC/CCV',
        postalCode: 'POSTAL CODE',
      },
      placeholders: {
        name: 'Pełne imię i nazwisko',
        number: '1234 5678 1234 5678',
        expiry: 'MM/RR',
        cvc: 'CVC',
        postalCode: '34567',
      },
    },
  },
  receiptDetailScreen: {
    headerTitle: 'Szczegóły rachunku',
    quantity: 'Ilość: $$',
    subtotal: 'Suma',
    support: {
      body: 'Enter details...\n\n---\nTransaction ID: $$',
      contactCta: 'Coś poszło nie tak? Daj nam znać',
      subject: 'Pomoc',
    },
    taxBreakdown: {
      headers: {
        rate: "%",
        netto: "Netto",
        brutto: "Brutto",
        tax: "Podatek",
        total: "Suma:"
      }
    },
    tax: 'Vat',
    taxCRV: 'CRV',
    total: 'Suma',
    title: 'Rachunek',
    processTime: 'Czas przetwarzania',
    shoppingTime: 'Czas zakupów',
    emptyMessage:
      'Dziękujemy za robienie zakupów z AiFi, nie zostaniesz obciążony tym razem. Oczekujemy z niecierpliwością kolejnej wizyty!',
    problem: 'Znalazłeś błąd? Proszę wyślij nam poprawiony paragon a my go rozpatrzymy.',
    date: 'Data',
    address: 'Dziękujemy za zakupy.',
    noItems: 'Brak przedmiotów',
    paymentStatus: 'Status płatności',
    draftProducts: 'Pracujemy nad tym!',
    retryPaymentSuccessInfo:
      'Dziękujemy za ponowienie płatności. Płatność powiodła się. Za chwilę na głównym ekranie aplikacji będzie dostępny kod QR umożliwiający wizytę w sklepie.',
    retryPaymentFailureInfo: 'Płatność nie powiodła się. Spróbuj ponownie później.',
    orderId: 'Numer zamówienia',
    feedbackSuccessTip: 'Thanks for sharing your feedback!',
    remindLaterTip: 'Great! We will reach out at a better time.',
    feedbackModalTitle: 'How likely would you recommend this shopping experience to your friend?',
    feedbackModalTip: 'Please rate on scale from 0 - 10',
    submitFeedback: 'Submit Feedback',
    remindLater: 'Remind me later',
    dontAskAgain: 'Don’t ask again',
    notLikely: 'Not Likely',
    veryLikely: 'Very Likely',
    listOfProducts: 'Zakupione produkty'
  },
  receiptDetailComplain: {
    title: 'Korekta rachunku',
    send: 'Wyślij',
    feedbackTitle: 'Odpowiedź',
    feedbackMessage:
      'Dziękujemy za przesłanie poprawki do rachunku! Przyjrzymy się temu jak najszybciej.',
    addItem: 'Dodaj produkt do rachunku',
    reportProblem: 'Zgłoś błąd',
    submitReview: 'Wyślij do rozpatrzenia',
    confirmTitle: 'Paragon wysłany',
    confirmMessage:
      'Twoje potwierdzenie zostało przesłane pomyślnie. Skontaktujemy się z Tobą w ciągu najbliższych 5 dni roboczych. Nie będziemy pobierać żadnych opłat oprócz pierwotnie zapłaconej kwoty. Dziękujemy za pomoc w ulepszaniu!',
    confirmButton: 'Zrobione',
    addMissingItem: 'Dodaj brakujący przedmiot',
    addMissingItemMessage: 'Dodaj wiadomość (opcjonalne)',
  },
  receiptAddItem: {
    title: 'Dodaj produkt',
  },
  receiptsScreen: {
    receipts: 'Rachunki',
    detail: '$$ items',
    detailItems: {
      zero: 'produktów',
      one: 'produkt',
      few: 'produkty',
      other: 'produktów',
    },
    headerTitle: 'Rachunki',
    noReceiptsMessage: 'Nie masz rachunków.\nWszystko jeszcze przed tobą!',
    processing1: 'Przetwarzanie',
    processing2: 'Transakcji',
    label: {
      contested: 'Rozpatrywany',
      draft: 'Oczekuje na płatność',
      empty: 'Pusty',
      paid: 'Zapłacony',
      pending: 'Oczekuje na weryfikację',
      refunded: 'Zwrócony',
      reviewed: 'Zmieniony',
      unknown: 'Nieznany',
      unpaid: 'Niezapłacony',
    },
    message: {
      contested: 'Zamówienie jest rozpatrywane',
      draft: 'Draft message',
      empty: 'Empty message',
      paid: 'Paid message',
      pending: 'Pending message',
      refunded: 'Refunded message',
      reviewd: 'Reviewed message',
      unpaid: 'Zamówienie nadal nie zostało opłacone poprawnie',
    },
    resetAll: 'RESET ALL',
    for: 'za',
    minutes: 'minut',
    hours: 'godziny',
    days: 'dni',
    stillWorkingOnIt: 'pracujemy nad tym!',
  },
  settingsScreen: {
    headerTitle: 'Ustawienia',
    items: {
      about: 'O nas',
      contact: 'Kontakt',
      logout: 'Wyloguj się',
      termsOfUse: 'Warunki korzystania',
      reportProblem: 'Zgłoszenie błędu',
      storybook: 'Storybook',
      disableNotification: 'Zablokuj powiadomienia',
    },
    security: 'Metoda płatności',
    aboutUs: 'O nas',
    myCards: 'Moje karty',
    more: 'Więcej',
    developer: 'DEVELOPER',
    taxId: 'NIP:',
    companyName: 'Firma:',
    address: 'Adres:',
  },
  signupScreen: {
    termsPrompt: 'Rejestrując się akceptujesz nasze ',
    termsPromptLink: 'warunki korzystania',
    title: 'Zarejestruj się',
    privacyPolicy: 'Privacy Policy',
    privacyPolicyPrefix: 'and read the ',
  },
  slideModal: {
    close: 'Zamknij',
    searchPlaceholder: 'Znajdź produkt',
  },
  notificationPermission: {
    title: '___RETAILER___ chce wysyłać Ci powiadomienia',
    text:
      'Chcę otrzymywać komunikaty push od ___RETAILER___ dotyczące działania aplikacji i związane z zakupami w sklepie bezobsługowym.',
  },
  receiptsHome: {
    receiptPortal: "Rachunki",
    selectPaymentType: "Wybierz użytą przez Ciebie formę płatności",
    card: "Karta",
    last4: "Ostatnie cztery cyfry Twojej metody płatności",
    last4Apple: "Ostatnie cztery cyfry Twojego numery Apple Pay",
    last4Google: "Ostatnie cztery cyfry Twojego numeru Google Pay",
    instructionApple: "Ostatnie cztery cyfry karty Apple Pay możesz znaleźć w aplikacji Portfel",
    instructionGoogle: "Ostatnie cztery cyfry karty Google Pay możesz znaleźć w aplikacji Portfel",
    expDate: "Data ważności",
    visitDate: "Data Twojej wizyty",
    howToFind: "Jak znaleźć numer karty?",
    submit: "Pobierz rachunki",
    registerEmail: "Wpisz swój email by otrzymywać powiadomienia o przyszłych rachunkach",
    email: "Adres email",
    registerEmailSuccess: "Adres email zapisany pomyślnie.",
    registerEmailFail: "Adres email nie został zapisany, ponieważ żaden rachunek nie jest powiązany z wprowadzonymi danymi karty i/lub datą wizyty.",
  },
}
