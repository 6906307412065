import React, { Dispatch, SetStateAction } from "react";
import { IFlavor, DefaultFlavor, defaultFeatureFlags, defaultAppConfig } from "../flavors/flavorTypes";
import { AppConfig, IFeatureFlags } from "../../../../shared/interface";

export interface IMasterContext {
  setStoreData: (storeData: StoreData) => Promise<void>;
  flavor: IFlavor;
  setFlavor: Dispatch<SetStateAction<IFlavor>>;
  featureFlags: IFeatureFlags
  appConfig: AppConfig
  setAppConfig: Dispatch<SetStateAction<AppConfig>>
}

export const defaultMasterContext: IMasterContext = {
  flavor: new DefaultFlavor(),
  featureFlags: defaultFeatureFlags,
  appConfig: defaultAppConfig,
  setAppConfig: () => {
    throw new Error("Not Implemented");
  },
  setStoreData: () => {
    throw new Error("Not Implemented");
  },
  setFlavor: () => {
    throw new Error("Not Implemented");
  },
};

export const MasterContext =
  React.createContext<IMasterContext>(defaultMasterContext);
