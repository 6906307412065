import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { timezoneDate } from "../utils";

class REWEFlavor extends Flavor {
  getMainColor() {
    return color2.BOXLL_RED;
  }

  getDashLineColor() {
    return color2.BOXLL_RED;
  }

  getCircleLogo() {
    return <></>;
  }

  getReceiptLogo() {
    return (
      <p style={{ textAlign: "center" }}>
        Lekkerland SE
        <br />
        Europaallee 57
        <br />
        50226 Frechen
        <br />
        UID-NR.: DE815866102
      </p>
    );
  }

  formatDateTime(dateTime: string, timezone: string) {
    const date = timezoneDate(dateTime, timezone);
    const formatDate = "YYYY-MM-DD";
    const formatTime = "HH:mm";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }

  getFeatureFlags() {
    return {
      ...super.getFeatureFlags(),
      hidePoweredByAiFi: true,
      orderDetailsShowHeader: false,
      orderDetailsShowDetailsAtTheTop: false,
      orderDetailsShowProductsListTitle: false,
      orderDetailsShowFooterOrderId: false,
      contestingEnabled: false,
      downloadReceiptEnabled: false,
    };
  }
}

const Singleton = new REWEFlavor();
export default Singleton;
