import * as Yup from "yup";
const digitsOnly = (value: string | undefined) => {
  return value ? /^\d+$/.test(value) : false;
};

const expDateTest = (value: string | undefined) => {
  return value ? /^\d\d\/\d\d$/.test(value) : false;
};

export const buildLoginPageSchema = (expDateRequired: boolean) =>
  Yup.object().shape({
    cardType: Yup.string(),
    customerPaymentInstrumentLast4: Yup.string()
      .required("The field is required")
      .length(4, "The field requires 4 characters")
      .test("Digits only", "The field should only contains digits", digitsOnly),

    ...(expDateRequired && {
      customerPaymentInstrumentExpDate: Yup.string().when("cardType", {
        is: (val: string) => val === "card",
        then: (schema) => {
          return schema
            .required("The field is required")
            .test(
              "Format",
              "The field is not in the valid format",
              expDateTest
            );
        },
      }),
    }),

    visitDate: Yup.string().required("The field is required"),
    captchaToken: Yup.string().required("Please pass captcha verification"),
    email: Yup.string().email("Must be a valid email"),
  });
