import i18n from "i18n-js";
import * as R from "ramda";
import { LanguageTag } from "../../../../shared/interface"
import { en } from "./en";
import { fr } from "./fr";
import { pl } from "./pl";
import { zh } from "./zh";
import { de } from "./de";
import { ja } from "./ja";
import { nl } from "./nl";

type Language = Readonly<{
  languageTag: LanguageTag;
  isRTL: boolean;
}>;

const translations = {
  en,
  fr,
  pl,
  zh,
  de,
  ja,
  nl,
};

const fallback: Language = { languageTag: "en", isRTL: false };

const { languageTag }: Language = fallback;

i18n.defaultLocale = "en";
i18n.locale = languageTag;
i18n.fallbacks = true;
i18n.translations = { 
  ...translations
};
i18n.pluralization.pl = (count) => {
  if (count === 0) {
    return ["zero"];
  }
  if (count === 1) {
    return ["one"];
  }
  if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) {
    return ["few"];
  }
  return ["other"];
};

export const translate = (...args: any): any =>
  R.memoizeWith(
    (key, config) => (config ? key + JSON.stringify(config) : (key as string)),
    (key, config) => i18n.t(key, config)
  )(args);

export const inject = (key: string, ...injectables: string[]) =>
  R.pipe(
    R.split("$$"),
    R.flip(R.zip)(R.append("", injectables)),
    R.flatten,
    R.join("")
  )(translate(key) as string);


export const setLocale = (locale: LanguageTag) => {
  i18n.locale = locale
}
