import * as R from "ramda";
import { useContext } from "react";
import { MasterContext } from "src/lib/masterContext";
import { color2 } from "src/styles";
import ReceiptDetailSection, { DetailItem } from "../../../../components/v2/ReceiptsDetailSection";
import { formatCurrency } from "../../../../lib/utils";
import { useReceiptOrder } from "../../ReceiptContext";

export const ProductDataBlock = () => {
  const order = useReceiptOrder();
  const {
    featureFlags: { hideTaxBreakdown, priceIncludesTax },
  } = useContext(MasterContext);

  const dataProduct = order.lineItems.flatMap((item) => {
    const price = priceIncludesTax
      ? item.totalPrice
      : item.subtotalPrice ?? item.totalPrice;
    const isDiscount = Number(price) < 0;
    const productLine: DetailItem[] = [
      {
        title: item.name,
        value: formatCurrency(price, order.currencyCode, item.quantity) +
          (!hideTaxBreakdown && !R.isNil(item.taxRates)
            ? " " + item.taxRates?.map((tax) => tax.name).join(" ")
            : ""),
        status: item.status,
        leftViewStyle: {
          wordBreak: "break-word",
          color: isDiscount ? color2.DISCOUNT : color2.BLUE_DARK,
        },
        rightViewStyle: {
          color: isDiscount ? color2.DISCOUNT : color2.BLUE_DARK,
        },
      },
    ];
    if (item.quantity > 1) {
      productLine.push({
        title: `${item.quantity} x ${formatCurrency(
          `${Number(price) / item.quantity}`,
          order.currencyCode
        )}`,
        leftViewStyle: { marginLeft: "3rem" },
      });
    }
    return productLine;
  });

  return <ReceiptDetailSection data={dataProduct} />;
};
