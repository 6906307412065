import { ITranslation } from './types'

export const fr: ITranslation = {
  addCardScreen: {
    headerTitle: 'Ajouter carte',
    stripeSetupIntentError: {
      header: 'Error $$',
      body: 'Something went wrong. Please try another card. Details: $$',
    },
  },
  button: {
    addCard: 'Ajouter carte',
    completeRegistration: "Finaliser l'inscription",
    confirm: 'Confirmer',
    continue: 'Continuer',
    getStarted: 'Démarrer',
    goToPayment: 'Options de paiement',
    login: "S'identifier",
    removeCard: 'Supprimer carte',
    signup: "S'inscrire",
    back: 'Back',
    skip: 'Eauter',
    error: 'Erreur',
    payment: 'Réessayer le paiement',
    ok: 'Ok',
    yes: 'Oui',
    no: 'Non',
  },
  contactUsScreen: {
    address: 'Adresse',
    email: 'Email',
    headerTitle: 'Contactez-nous',
    phone: 'Téléphone',
    dataProtectionEmail: 'Data Deletion',
    dataProtectionTitle:
      'If you would like to request your personal data to be deleted, please send us an email to: ',
  },
  api: {
    E_FORCED_LOGOUT: `Vous avez été déconnecté car l'accès n'est pas autorisé.`,
  },
  errors: {
    error: 'Erreur',
    E_NO_INTERNET: 'No internet connection',
    E_CUSTOMER_NOT_FOUND: 'Client introuvable',
    E_CUSTOMER_INVALID_CREDENTIALS: "Votre nom d'utilisateur ou votre mot de passe est invalide",
    E_FORBIDDEN: 'Accès interdit',
    E_NOT_FOUND: 'Une erreur est apparue',
    E_ORDER_NOT_FOUND: "La commande n'existe pas",
    E_PAYMENT_ERROR: 'Une erreur est apparue en contactant notre service de paiement',
    E_STORE_NOT_FOUND: "Le magasin n'apparait pas",
    E_UNAUTHORIZED: 'Accès interdit',
    E_CUSTOMER_PAYMENT_METHOD_NOT_FOUND: "Le client n'a pas de moyen de paiement",
    E_INVALID_BODY: 'Veuillez remplir tous les champs et/ou corrigez champs incorrects',
    E_ORDER_CUSTOMER_RELATION: "La commande n'appartient pas au client",
    E_RESET_CODE_ALREADY_GENERATED:
      'Un courriel de réinitialisation de mot de passe a été envoyé à cette adresse e-mail. Veuillez réessayer plus tard.',
    E_RESET_CODE_INVALID: 'Le mot de passe de reinitialisation est incorrect',
    E_RESET_CODE_NOT_FOUND: 'Le token de réinitialisation est introuvable ou incorrect',
    E_SHOPIFY_DUPLICATE_EMAIL: 'Cet email existe déjà',
    E_SHOPIFY_DUPLICATE_PHONE: 'Ce numéro de téléphone existe déjà',
    E_SHOPIFY_INVALID_ORDER_ID: "La commande n'existe pas",
    E_SHOPIFY_CUSTOMER_NOT_FOUND: 'Client introuvable',
    E_CUSTOMER_NO_PAYMENT_METHOD: "Le client n'a pas de moyen de paiement",
    E_CUSTOMER_UNSETTLED_TRANSACTIONS: 'Customer does not have payment method set up',
    E_CUSTOMER_CARD_EXPIRED: "Customer's card is expired",
    SOMETHING_WRONG: 'Une erreur s’est produite. Veuillez réessayer.',
  },
  forgotPasswordScreen: {
    title: 'Mot de passe oublié?',
    wrongCodeErrorMessage: 'Incorrect code',
    wrongEmailErrorMessage: 'Wrong email',
    codeErrorMessage: 'Field is not allowed to be empty',
    emailErrorMessage: 'Field is not allowed to be empty',
    passwordErrorMessage: 'Field is not allowed to be empty',
    shortPasswordErrorMessage: 'Password lenght must be at least 8 characters long',
    alreadyHaveCode: 'Already have a code',
  },
  form: {
    email: 'Adresse email',
    emailPlaceholder: 'email@example.com',
    firstName: 'Prénom',
    firstNamePlaceholder: 'John',
    lastName: 'Nom de famille',
    lastNamePlaceholder: 'Doe',
    newPassword: 'Nouveau mot de passe',
    password: 'Mot de passe',
    passwordPlaceholder: '8+ personnages',
    resetCode: 'Réinitialiser le mot de passe',
    resetCodePlaceholder: '12345',
    phone: 'Phone',
    phonePlaceholder: '13888888888',
    productName: 'Nom du produit',
    productQuantity: 'Quantité',
    labelPasswordShow: 'EXPOSER',
    labelPasswordHide: 'MASQUER',
    taxId: `numéro d'identification fiscale (facultatif)`,
    taxIdPlaceholder: '1234567890',
    companyName: `Nom de l'entreprise (facultatif)`,
    companyNamePlaceholder: 'Company Inc.',
    address: 'Adresse',
    addressPlaceholder: 'Rue 1',
    addressError: `l'adresse ne doit pas être vide`,
    firstNameErrorMessage: 'le prénom ne doit pas être vide',
    lastNameErrorMessage: 'le nom de famille ne doit pas être vide',
    emailErrorMessage: `l'e-mail ne doit pas être vide`,
    invalidEmailErrorMessage: `Ceci n'est pas un email valide`,
    passwordErrorMessage: 'le mot de passe ne doit pas être vide',
    shortPasswordErrorMessage: `la longueur du mot de passe doit être d'au moins 8 caractères`,
    taxIdErrorMessage: `Ce n'est pas un numéro d'identification fiscale valide`,
    taxIdEmptyErrorMessage: `Le numéro d'identification fiscale ne doit pas être vide`,
    companyNameEmptyErrorMessage: `Le nom de l'entreprise ne doit pas être vide`,
    phoneEmptyErrorMessage: 'Phone is not allowed to be empty',
    phoneInvalidErrorMessage: 'A phone number should be 8-15 digits(include country code)',
  },
  loginScreen: {
    forgotPassword: 'Mot de passe oublié?',
    subtitle: "S'identifier ici avec votre email et mot de passe",
    title: 'Bienvenue',
  },
  homeScreen: {
    cardInvalidSubtitle:
      "Vous avez besoin d'une méthode valide de paiement pour accéder au magasin",
    cardValidTitle: 'Scannez pour entrer',
    partyText: 'Nombre de personne dans votre groupe:',
    errorNoCard: `Aucune carte bancaire n'a été ajoutée à votre compte. Veuiilez essayer une nouvelle carte.`,
    errorNoCardButton: 'Veullez rajouter une carte bancaire',
    greeting: 'Bonjour',
    errorUnpaid: `Vous devrez payer tous vos reçus pour regagner l'accès aux magasins`,
    errorUnpaidButton: 'Consulter le reçu impayé',
    errorExpired: 'Toutes vos cartes ont expiré. Veuillez ajouter une nouvelle carte.',
    errorExpiredButton: 'Ajouter une carte',
    errorUnknown: 'Erreur inconnue',
  },
  menu: {
    access: 'Accès',
    payment: 'Paiement',
    receipts: 'Reçus',
    settings: 'Paramètres',
  },
  onboardingScreen: {
    subtitle1: 'Accédez au magasin en utilisant le code dans votre application',
    title1: 'Scannez pour entrer',
    subtitle2:
      'Laissez-les accéder au magasin en premier, en scannant votre code, puis entrez vous-même.',
    title2: 'Plusieurs personnes?',
    subtitle3: "Tout produit retiré d'étagère est automatique ajouté à votre panier virtuel",
    title3: 'Faites vos courses',
    subtitle4:
      'Quand vous avez fini, vous pouvez simplement quitter le magasin. Vous recevrez votre ticket de caisse.',
    title4: 'Prêt? Vous pouvez entrez.',
  },
  paymentScreen: {
    card: {
      subtitle: 'Ceci est votre méthode de paiement actuelle pour régler vos achats.',
    },
    endsWith: '* * * * $$',
    noCard: {
      subtitle:
        "Vous n'avez enregistré aucune méthode de paiement. Veuillez ajouter une carte en appuyant sur le bouton ci-dessous.",
    },
    removeCard: {
      cancel: 'Annuler',
      delete: 'Supprimer',
      subtitle: 'Voulez-vous vraiment supprimer votre carte?',
      title: 'Supprimer carte',
    },
    title: 'Moyen de paiement',
    addCreditCard: {
      cardView: {
        labels: {
          expiry: 'MOIS/AN',
        },
      },
      labels: {
        name: 'NOM DU TITULAIRE DE LA CARTE',
        number: 'NUMÉRO DE CARTE',
        expiry: 'EXPIRATION',
        cvc: 'CVC/CCV',
        postalCode: 'CODE POSTAL',
      },
      placeholders: {
        name: 'Nom et prénom',
        number: '1234 5678 1234 5678',
        expiry: 'MM/YY',
        cvc: 'CVC',
        postalCode: '34567',
      },
    },
  },
  receiptDetailScreen: {
    headerTitle: 'Details du reçu',
    quantity: 'Quantité: $$',
    subtotal: 'Sous-total',
    support: {
      body: 'Entrer détails...\n\n---\nNuméro de transaction: $$',
      contactCta: 'Vous avez eu une problème? faites-le nous savoir',
      subject: 'Demande de support',
    },
    taxBreakdown: {
      headers: {
        rate: "Tax %",
        netto: "Netto",
        brutto: "Brutto",
        tax: "Tax",
        total: "Total:"
      }
    },
    tax: 'Tax',
    taxCRV: 'CRV',
    total: 'Total',
    title: 'Reçu',
    processTime: 'Temps de traitement',
    emptyMessage: `Merci d'avoir acheté avec AiFi, vous n'êtes pas facturé cette fois. Nous attendons votre prochaine visite avec impatience !`,
    draftProducts: `Nous traitons votre parcours d'achat, votre reçu sera disponible sous peu.`,
    retryPaymentSuccessInfo: 'Paiement réussi, merci!',
    retryPaymentFailureInfo: `Le paiement n'a pas réussi, veuillez réessayer plus tard.`,
    orderId: 'Ordre ID',
    shoppingTime: 'Temps de magasinage',
    problem: `Vous avez trouvé un problème ? Veuillez nous envoyer le reçu modifié pour un examen et nous l'examinerons.`,
    date: 'Date',
    address: 'Merci de magasiner avec nous.',
    noItems: 'Aucun article sélectionné',
    paymentStatus: 'Statut de paiement',
    feedbackSuccessTip: 'Thanks for sharing your feedback!',
    remindLaterTip: 'Great! We will reach out at a better time.',
    feedbackModalTitle: 'How likely would you recommend this shopping experience to your friend?',
    feedbackModalTip: 'Please rate on scale from 0 - 10',
    submitFeedback: 'Submit Feedback',
    remindLater: 'Remind me later',
    dontAskAgain: 'Don’t ask again',
    notLikely: 'Not Likely',
    veryLikely: 'Very Likely',
    listOfProducts: 'Produits'
  },
  receiptDetailComplain: {
    title: "Rétroaction d'utilisateur",
    send: 'Envoyer',
    feedbackTitle: 'Réponse',
    feedbackMessage: `Merci d'avoir soumis une correction à votre reçu! Nous l'étudierons dans les plus brefs délais.`,
    addItem: 'Ajouter le produit à la réception',
    confirmMessage:
      'Votre reçu a été soumis avec succès. Nous vous répondrons dans les 5 prochains jours ouvrables. Nous ne facturerons rien en plus du montant initialement payé. Merci de nous aider à nous améliorer!',
    reportProblem: 'Signaler un problème',
    submitReview: 'Envoyer pour avis',
    confirmTitle: 'Reçu envoyé',
    confirmButton: 'Fait',
    addMissingItem: 'Ajouter un élément manquant',
    addMissingItemMessage: 'Ajouter un message (facultatif)',
  },
  receiptAddItem: {
    title: 'Ajouter un produit',
  },
  receiptsScreen: {
    detail: '$$ articles',
    headerTitle: 'Reçus',
    noReceiptsMessage: "Aucun reçu pour l'instant.\nIl y'a un début à tout.",
    processing1: 'Traitement',
    processing2: 'Transaction',
    minutes: 'minutes',
    hours: 'les heures',
    days: 'journées',
    stillWorkingOnIt: 'Travaille toujours dessus!',
    receipts: 'Reçus',
    detailItems: {
      one: 'élément',
      other: 'éléments',
    },
    label: {
      contested: 'EN REVUE',
      draft: 'BROUILLON',
      empty: 'VIDER',
      paid: 'PAYÉ',
      pending: 'BROUILLON',
      refunded: 'REMBOURSÉ',
      reviewed: 'MODIFIÉ',
      unknown: 'Inconnu',
      unpaid: 'NON PAYÉ',
    },
    message: {
      contested: 'Message contesté',
      draft: 'Brouillon de message',
      empty: 'Message vide',
      paid: 'Message payant',
      pending: 'Message en attente',
      refunded: 'Message remboursé',
      reviewd: 'Message examiné',
      unpaid: 'Message non payé',
    },
    resetAll: 'EFFACER TOUT',
    for: 'pour',
  },
  settingsScreen: {
    headerTitle: 'Paramètres.',
    items: {
      about: 'À propos',
      contact: 'Contactez-nous',
      logout: 'Se déconnecter',
      termsOfUse: "conditions d'utilisation",
      disableNotification: "Notification d'interdiction",
      reportProblem: 'Signaler un problème',
      storybook: 'Storybook',
    },
    taxId: `Numéro d'identification fiscale:`,
    companyName: 'Entreprise:',
    address: 'Adresse:',
    security: 'SÉCURITÉ',
    aboutUs: 'À PROPOS DE NOUS',
    myCards: 'Mes cartes',
    more: 'SUITE',
    developer: 'DÉVELOPPEUR',
  },
  signupScreen: {
    termsPrompt: 'En vous inscrivant, vous acceptez les',
    termsPromptLink: "Conditions d'utilisation",
    title: "S'inscrire",
    privacyPolicy: 'Privacy Policy',
    privacyPolicyPrefix: 'and read the ',
  },
  notificationPermission: {
    title: '___RETAILER___ wants to send you notifications',
    text:
      'I would like to receive push notifications from ___RETAILER___ regarding functioning of the automated store.',
  },
  slideModal: {
    close: 'Fermer',
    searchPlaceholder: 'Rechercher un élément',
  },
  registerLoginScreen: {
    register: `S'inscrire`,
    login: 'Connexion',
  },
  receiptsHome: {
    receiptPortal: "Receipt Portal",
    selectPaymentType: "Select the payment type that you used",
    card: "Card",
    last4: "Last four digits of your payment method",
    last4Apple: "Last four digits of your Apple Pay Number",
    last4Google: "Last four digits of your Google Pay Number",
    instructionApple: "Locate last four digits of your Apple Pay Card Number in your Apple Wallet",
    instructionGoogle: "Locate last four digits of your Google Pay Card Number in your Wallet",
    expDate: "Expiration date",
    visitDate: "Date of your visit",
    howToFind: "How to find a card number?",
    submit: "Retrieve Receipt(s)",
    registerEmail:
      "Enter your email to subscribe to future receipts",
    email: "E-mail address",
    registerEmailSuccess: "Email address updated successfully.",
    registerEmailFail: "Email address wasn't updated because there wasn't any receipts associated with provided credit card information and/or visit date.",
  },
}
