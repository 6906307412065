import { DashLine } from "src/components/v2/Dashline";
import { ReceiptStructureEntry } from "../structure";
import { CardDataBlock } from "./CardDataBlock";
import { DownloadReceiptBlock } from "./DownloadReceiptBlock";
import { EmptySpaceBlock } from "./EmptySpaceBlock";
import { ExtraTextBlock } from "./ExtraTextBlock";
import { FooterTextBlock } from "./FooterTextBlock";
import { ImageBlock } from "./ImageBlock";
import { OrderDateTimeBlock } from "./OrderDateTimeBlock";
import { OrderPaymentStatus } from "./OrderPaymentStatus";
import { PoweredByAiFiBlock } from "./PoweredByAiFiBlock";
import { ProcessTimeBlock } from "./ProcessTimeBlock";
import {
  ProductChargeDataBlock,
  SubtotalBlock,
  TaxBlock,
  TaxCRVBlock,
  TotalBlock,
} from "./ProductChargeDataBlock";
import { ProductDataBlock } from "./ProductDataBlock";
import { ProductTaxBreakdownBlock } from "./ProductTaxBreakdownBlock";
import { ReceiptLogoBlock } from "./ReceiptLogoBlock";
import { ReportProblemBlock } from "./ReportProblemBlock";
import { TextBlock } from "./TextBlock";
import { FooterOrderIdBlock } from "./FooterOrderIdBlock";
import { OrderIdWithDateBlock } from "./OrderIdWithDateBlock";

export type ReceiptBlockProps = {
  block: ReceiptStructureEntry;
};

export function ReceiptBlockRouter(props: ReceiptBlockProps) {
  const { block } = props;

  if (block.element === "Text") {
    return <TextBlock blockSettings={block} />;
  }
  if (block.element === "OrderIdWithDate") {
    return <OrderIdWithDateBlock />;
  }
  if (block.element === "EmptySpace") {
    return <EmptySpaceBlock blockSettings={block} />;
  }
  if (block.element === "Logo") {
    return <ReceiptLogoBlock />;
  }
  if (block.element === "Image") {
    return <ImageBlock blockSettings={block} />;
  }
  if (block.element === "DashLine") {
    return <DashLine />;
  }
  if (block.element === "ProductsLines") {
    return <ProductDataBlock />;
  }
  if (block.element === "ChargeData") {
    return <ProductChargeDataBlock />;
  }
  if (block.element === "TaxBreakdown") {
    return <ProductTaxBreakdownBlock />;
  }
  if (block.element === "CardData") {
    return <CardDataBlock />;
  }
  if (block.element === "DateTime") {
    return <OrderDateTimeBlock />;
  }
  if (block.element === "ProcessTime") {
    return <ProcessTimeBlock />;
  }
  if (block.element === "DownloadReceipt") {
    return <DownloadReceiptBlock />;
  }
  if (block.element === "ReportProblem") {
    return <ReportProblemBlock />;
  }
  if (block.element === "ExtraText") {
    return <ExtraTextBlock />;
  }
  if (block.element === "PoweredByAifi") {
    return <PoweredByAiFiBlock />;
  }
  if (block.element === "FooterOrderIdBlock") {
    return <FooterOrderIdBlock />;
  }
  if (block.element === "FooterText") {
    return <FooterTextBlock />;
  }
  if (block.element === "PaymentStatus") {
    return <OrderPaymentStatus />;
  }
  if (block.element === "Subtotal") {
    return <SubtotalBlock />;
  }
  if (block.element === "TaxCRV") {
    return <TaxCRVBlock />;
  }
  if (block.element === "Tax") {
    return <TaxBlock />;
  }
  if (block.element === "Total") {
    return <TotalBlock />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- used as build-time assertion
  const _unknownBlock: never = block;
  return null;
}
