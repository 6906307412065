import { ITranslation } from "./types";

export const nl: ITranslation = {
  addCardScreen: {
    headerTitle: "Voeg kaart toe",
    stripeSetupIntentError: {
      header: "Foutmelding: $$",
      body: "Er is iets foutgegaan. Probeer aub een andere kaart. Details: $$",
    },
  },
  button: {
    addCard: "kaart",
    completeRegistration: "Registratie compleet",
    confirm: "Bevestig",
    continue: "Ga verder",
    getStarted: "Get started",
    goToPayment: "Betaalopties",
    login: "Log in",
    removeCard: "Verwijder kaart",
    signup: "Meld je aan",
    back: "Terug",
    skip: "Overslaan",
    error: "Error",
    payment: "Herhaal betaling opnieuw",
    ok: "Ok",
    yes: "Ja",
    no: "Nee",
  },
  contactUsScreen: {
    address: "Adres",
    email: "Email",
    headerTitle: "Neem contact met ons op",
    phone: "Telefoon",
    dataProtectionEmail: "Data verwijdering",
    dataProtectionTitle:
      "Indian u uw persoonlijke gegevens bij ons wil laten verwijderen, stuur een een e-mail aan: ",
  },
  api: {
    E_FORCED_LOGOUT: "U bent uitgelogd omdat de connectie is verlopen.",
  },
  errors: {
    error: "Error",
    // E_CONFLICT: ‘Gebruiker bestaat al',
    E_NO_INTERNET: "Geen internet connectie",
    E_CUSTOMER_NOT_FOUND: "Klant niet gevonden",
    E_CUSTOMER_INVALID_CREDENTIALS: "Onjuist email of password",
    E_FORBIDDEN: "Geen Toegang",
    E_NOT_FOUND: "Gegevens niet gevonden",
    E_ORDER_NOT_FOUND: "Order bestaat niet",
    E_PAYMENT_ERROR:
      "Er is helaas iets foutgegaan bij het contact opnemen met de betaalservice",
    E_STORE_NOT_FOUND: "Store niet gevonden",
    E_UNAUTHORIZED: "Geen toegang, geen autorisatie",
    E_CUSTOMER_PAYMENT_METHOD_NOT_FOUND:
      "Er is geen betaalmiddel gekoppeld aan de klant",
    E_INVALID_BODY:
      "Vul ale veld in of het formaat in (een van) de velden is onjuist",
    E_ORDER_CUSTOMER_RELATION: "Order hoort niet bij deze klant",
    E_RESET_CODE_ALREADY_GENERATED:
      "Resetcode voor dit email adres is al gestuurd. Probeer later opnieuw.",
    E_RESET_CODE_INVALID: "Reset code is onjuist",
    E_RESET_CODE_NOT_FOUND: "Reset token niet gevonden of niet meer geldig",
    E_SHOPIFY_DUPLICATE_EMAIL: "Email adres is al in gebruik",
    E_SHOPIFY_DUPLICATE_PHONE: "Telefoonnummer is al in gebruik",
    E_SHOPIFY_INVALID_ORDER_ID: "Ordernummer bestaat niet",
    E_SHOPIFY_CUSTOMER_NOT_FOUND: "Klant niet gevonden",
    E_CUSTOMER_NO_PAYMENT_METHOD:
      "Klant heeft geen geldig betaalmiddel gekoppeld",
    E_CUSTOMER_UNSETTLED_TRANSACTIONS:
      "De Laatste transactie is mislukt. Contact onze Support afdeling.",
    E_CUSTOMER_CARD_EXPIRED: "Kaart van de klant is niet meer geldig",
    SOMETHING_WRONG: "Er ging helaas iets fout. Probeer later opnieuw.",
  },
  forgotPasswordScreen: {
    title: "Wachtwoord vergeten?",
    wrongCodeErrorMessage: "Onjuiste code",
    wrongEmailErrorMessage: "Onjuiste email",
    codeErrorMessage: "Veld mag niet leeg zijn",
    emailErrorMessage: "Veld mag niet leeg zijn",
    passwordErrorMessage: "Veld mag niet leeg zijn",
    shortPasswordErrorMessage:
      "Lengte van Wachtwoord moet minimaal 8 karakters lang zijn",
    alreadyHaveCode: "Er is al een code",
  },
  form: {
    email: "EMAIL ADDRESS",
    emailPlaceholder: "email@example.com",
    firstName: "FIRST NAME",
    firstNamePlaceholder: "John",
    lastName: "LAST NAME",
    lastNamePlaceholder: "Doe",
    newPassword: "Nieuw Wachtwoord",
    password: "WACHTWOORD",
    passwordPlaceholder: "8+ characters",
    resetCode: "Reset code",
    resetCodePlaceholder: "12345",
    productName: "Product naam",
    productQuantity: "Aantal",
    labelPasswordShow: "TOON",
    labelPasswordHide: "VERBERG",
    taxId: "tax id (optional)",
    taxIdPlaceholder: "1234567890",
    companyName: "Bedrijfsnaam (optioneel)",
    companyNamePlaceholder: 'Bedrijf',
    phone: "Telefoon",
    phonePlaceholder: "0688888888",
    address: "Adres",
    addressPlaceholder: "Straat , Postcode  Plaats",
    addressError: "Adres mag niet leeg zijn",
    firstNameErrorMessage: "Voornaam mag niet leeg zijn",
    lastNameErrorMessage: "Achternaam mag niet leeg zijn",
    emailErrorMessage: "Email mag niet leeg zijn",
    invalidEmailErrorMessage: "Dit is geen geldige email",
    passwordErrorMessage: "Wachtwoord mag niet leeg zijn ",
    shortPasswordErrorMessage:
      "Wachtwoord lengte moet minimaal 8 karakters lang zijn",
    taxIdErrorMessage: "Dit is geen geldig BTW-nummer",
    taxIdEmptyErrorMessage: "BTW-nummer mag niet leeg zijn",
    companyNameEmptyErrorMessage: "Bedrijfsnaam mag niet leeg zijn",
    phoneEmptyErrorMessage: "Telefoonnummer mag niet leeg zijn",
    phoneInvalidErrorMessage:
      "Een telefoonnummer moet tussen de 8 en 15 getallen zijn (inclusief landcode)",
  },
  loginScreen: {
    forgotPassword: "Wachtwoord vergeten?",
    subtitle: "Log in met email en wachtwoord",
    title: "Welkom terug",
  },
  homeScreen: {
    cardInvalidSubtitle:
      "Vul alle gegevens in van uw account om toegang tot de stores te krijgen.",
    cardValidTitle: "Scan voor toegang",
    partyText: "Aantal mensen in uw groep:",
    greeting: "Hallo",
    errorUnpaid:
      "Er zijn openstaande kassabonnen die eerst betaald moeten worden voordat u toegang krijgt tot de store",
    errorUnpaidButton: "Bekijk onbetaalde kassabonnen",
    errorExpired:
      "Alle kaarten zijn niet meer geldig. Voeg een geldige kaart toe.",
    errorExpiredButton: "Voeg een kaart toe",
    errorNoCard:
      "Er is geen kaart toegevoegd tot uw account. Voeg een kaart toe.",
    errorNoCardButton: "Voeg een kaart toe",
    errorUnknown: "Onbekende foutmelding",
  },
  menu: {
    access: "Toegang",
    payment: "Betalingen",
    receipts: "Kassabonnen",
    settings: "Contact",
  },
  onboardingScreen: {
    subtitle1: "Geef Store door de toegangscode van de app in te voeren...",
    title1: "Scan voor volgende stap",
    subtitle2:
      "Geef toegang tot de store door het scannen van de toegangscode, ga daarna zelf naar binnen.",
    title2: "Meerdere mensen tegelijkertijd?",
    subtitle3:
      "Alles wat u van het schap pakt voor in uw virtuele boodschappenmand toegevoegd.",
    title3: "Start uw bezoek",
    subtitle4:
      "Als u klaar bent, kunt u de Store verlaten zonder verdere actie vanuit uw kant. Wij sturen de kassabon aan u.",
    title4: "Klaar , u kunt uw artikelen gaan kopen ",
  },
  registerLoginScreen: {
    register: "Register",
    login: "Log in",
  },
  paymentScreen: {
    card: {
      subtitle:
        "Dit is uw huidige betaalmiddel dat bij ons bekend is om te betalen.",
    },
    endsWith: "* * * * $$",
    noCard: {
      subtitle:
        "U heeft geen betaalmiddel in ons systeem, Voeg een kaart toe door op de onderstaande knop te drukken.",
    },
    removeCard: {
      cancel: "Annuleer",
      delete: "Verwijder",
      subtitle: "Bent u zeker dat de kaart verwijderd moet worden?",
      title: "Verwijder kaart",
    },
    title: 'Betaalmiddel',
    addCreditCard: {
      cardView: {
        labels: {
          expiry: "MAAND/JAAR",
        },
      },
      labels: {
        name: "KAARTHOUDER NAAM",
        number: "KAARTNUMMER",
        expiry: "GELDIG TOT",
        cvc: "CVC/CCV",
        postalCode: "POSTCODE",
      },
      placeholders: {
        name: "Volledige Naam",
        number: "1234 5678 1234 5678",
        expiry: "MM/JJ",
        cvc: "CVC",
        postalCode: "1111XX",
      },
    },
  },
  receiptDetailScreen: {
    headerTitle: "Kassabon gegevens",
    quantity: "Aantal: $$",
    subtotal: "Subtotaal",
    support: {
      body: "Geef details...\n\n---\nTransactie ID: $$",
      contactCta:
        "Indien de gegevens volgens u niet juist zijn, neem contact op",
      subject: "Support request",
    },
    taxBreakdown: {
      headers: {
        rate: "BTW %",
        netto: "Netto",
        brutto: "Bruto",
        tax: "BTW",
        total: "Totaal:",
      },
    },
    tax: "BTW",
    taxCRV: "CRV",
    total: "Totaal",
    title: "Bon",
    processTime: "Procetijd",
    shoppingTime: "Shopping tijd",
    emptyMessage:
      "Bedankt voor het winkelen bij AiFi, er wordt niets in rekening gebracht. Graag tot de volgende keer!",
    problem:
      "Is er een probleem met uw bon? Stuur ons de onjuiste gegevens (aangepaste bon) en wij zullen dit uiteraard direct bekijken ",
    date: "Datum",
    address: "Bedankt voor uw bezoek.",
    noItems: "Aantal artikelen in de bon",
    paymentStatus: "Betaalstatus",
    draftProducts:
      "Wij zijn uw winkelbezoek aan het processe, uw kassabon zal binnenkort beschikbaar zijn.",
    retryPaymentSuccessInfo: "Betaling gelukt, hartelijk dank!",
    retryPaymentFailureInfo: "Betaling niet gelukt, probeer later opnieuw.",
    orderId: "Order ID",
    feedbackSuccessTip: "Bedankt voor uw feedback!",
    remindLaterTip: "Bedankt! We zullen u op een beter moment weer benaderen.",
    feedbackModalTitle:
      "Hoe waarschijnlijk is het dat je ons aan een vriend zou aanbevelen?",
    feedbackModalTip: "Geef uw mening op een schaal van 0 - 10 ",
    submitFeedback: "Bevestig Feedback",
    remindLater: "Niet nu ",
    dontAskAgain: "Niet meer vragen in de toekomst",
    notLikely: "Zeker niet",
    veryLikely: "Zeker ja",
    listOfProducts: "Producten",
  },
  receiptDetailComplain: {
    title: "Klant feedback",
    send: "Stuur",
    feedbackTitle: "Antwoord",
    feedbackMessage:
      "Bedankt voor uw terugkoppeling over uw kassabon. Wij gaan er zo snel mogelijk mee aan de slag!",
    addItem: "Voeg product toe aan kassabon",
    reportProblem: "Rapporteer een probeem met deze kassabon",
    submitReview: "Stuur voor een review",
    confirmTitle: "Kassabon-melding verstuurd",
    confirmMessage:
      "Uw kassabon-melding is succesvol verstuurd and wij reageren zo snel mogelijk. Wij zullen geen extra kosten in rekening brengen op de originele kassabon. Bedankt voor uw help om onze te verbeteren in de toekomst!",
    confirmButton: "Klaar",
    addMissingItem: "Voeg missende item toe",
    addMissingItemMessage: "Voeg boodschap toe (optioneel)",
  },
  receiptAddItem: {
    title: "Voeg product toe",
  },
  receiptsScreen: {
    receipts: "Kassabonnen",
    detail: "$$ items",
    detailItems: {
      one: "item",
      other: "items",
    },
    headerTitle: "Kassabonnen",
    noReceiptsMessage:
      "Er zijn momenteel nog geen kassabonnenyet.\nEveryone likes to start with something new",
    processing1: "Processing",
    processing2: "Transactie",
    label: {
      contested: "IN REVIEW",
      draft: "CONCEPT",
      empty: "LEEG",
      paid: "BETAALD",
      pending: "DRAFT",
      refunded: "TERUGGESTORT",
      reviewed: "HERZIEN",
      unknown: "ONBEKEND",
      unpaid: "ONBETAALD",
    },
    message: {
      contested: "Betwiste boodschap",
      draft: "Concept boodschap",
      empty: "Lege boodschap",
      paid: "Betaalde boodschap",
      pending: "In bewerking boodschap",
      refunded: "Teruggestorte boodschap",
      reviewd: "Behandelde boodschap",
      unpaid: "Onbetaalde boodschap",
    },
    resetAll: "RESET ALL",
    for: "voor",
    minutes: "minuten",
    hours: "uren",
    days: "dagen",
    stillWorkingOnIt: "nog steeds in behandeling!",
  },
  settingsScreen: {
    headerTitle: "Settings",
    items: {
      about: "Over",
      contact: "Contacteer ons",
      logout: "Log out",
      reportProblem: "Rapporteer een probleem",
      termsOfUse: "Terms of Use",
      storybook: "Storybook",
      disableNotification: "Disable notification",
    },
    security: "SECURITY",
    aboutUs: 'OVER ONS',
    myCards: "Mijn kaarten",
    more: "MEER",
    developer: "DEVELOPER",
    taxId: "BTW ID:",
    companyName: "Bedrijf:",
    address: "Adres:",
  },
  signupScreen: {
    termsPrompt: "Door je aan te melden accepteer je onze ",
    termsPromptLink: "Terms of Use",
    title: "Meld aan",
    privacyPolicy: "Privacy Policy",
    privacyPolicyPrefix: "en lees de",
  },
  slideModal: {
    close: "Close",
    searchPlaceholder: "Zoek item",
  },
  notificationPermission: {
    title: "___RETAILER___ wil je meldingen en informatie sturen",
    text: "Ik wil de (push) meldingen en informatie ontvangen van  ___RETAILER___ hoe deze frictionloze winkel werkt.",
  },
  receiptsHome: {
    receiptPortal: "Kassabonnen Portaal",
    selectPaymentType: "Kies het betaalmiddel dat u heeft gebruikt",
    card: "Kaart",
    last4: "Geef de laatste 4 cijfers van ur betaalkaart",
    last4Apple: "Geef de laatste 4 cijfers van uw Apple Pay accountnummer",
    last4Google: "Geef de laatste 4 cijfers van uw Google Pay accounnummer",
    instructionApple:
      "Vind de laatste 4 cijfers van uw Apple Pay Card in uw Apple Wallet",
    instructionGoogle:
      "Vind de laatste 4 cijfers van uw Google Pay Card  in uw Wallet",
    expDate: "Geldig tot",
    visitDate: "Datum van uw bezoek",
    howToFind: "Hoe kan ik mijn kaartnummer vinden?",
    submit: "Zoek Kassabon(nen)",
    registerEmail: "Geef uw e-mail om uw kassabonnen te ontvangen",
    email: "E-mail adres",
    registerEmailSuccess: "Email adres succesvol opgeslagen.",
    registerEmailFail:
      "Email adres niet opgeslagen omdat er geen kassabonnen aanwezig zijn met deze betaalkaart en/of datum van bezoek.",
  },
};
