import { Text, View } from "react-native";
import { config } from "src/lib/config";
import { useReceiptOrder } from "../../ReceiptContext";
import { styles } from "../ReceiptFooter/styles";


export const FooterTextBlock = () => {
  const order = useReceiptOrder();

  let footerText: string | undefined = config.messages.footerPaid;
  if (order.status === "contested") {
    footerText = config.messages.footerInReview;
  } else if (order.status === "reviewed") {
    footerText = config.messages.footerReviewed;
  }

  return footerText ?
    <View style={styles.addressWrapper}>
      <Text style={styles.address}>{footerText}</Text>
    </View>
  : null;
};
