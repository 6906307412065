import { font } from "../../../styles/themes";
import { StyleSheet } from "react-native";
import { color2, dpToPx } from "../../../styles";

export const cssStyles = {
  text: {
    fontSize: dpToPx(14),
    color: color2.BLUE_DARK,
    fontFamily: font.family.montserrat,
    fontWeight: "500" as const,
  },
  leftText: {
    flex: 2,
    textAlign: "left" as const,
    marginRight: dpToPx(8),
  },
  rightText: {
    textAlign: "right" as const,
    marginLeft: dpToPx(5),
  },
}

export const styles = StyleSheet.create({
  wrapper: {
    padding: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  text: cssStyles.text,
  leftText: cssStyles.leftText,
  rightText: cssStyles.rightText,
});
